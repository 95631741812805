<template>
  <div>
    <CreditLetterModalHeader
      v-if="creditLetter.details.id"
      :creditLetter="creditLetter"
    />

    <aTabs
      type="card"
      class="miles-cards-tabs"
      :defaultActiveKey="activeTab"
      @change="onChangeTabs"
    >
      <aTabPane key="data" tab="Dados da Carta de Crédito">
        <CreditLetterForm
          class="mt-20"
          v-if="creditLetter.details.id !== undefined"
          action="edit"
          :creditCardStatusList="creditCardStatusList"
          :creditLetter="creditLetter"
        />
      </aTabPane>

      <aTabPane key="extract">
        <template #tab>
          Extrato
          <a-tag v-if="totalTransactions != 0" class="ml-5" color="blue">
            {{ totalTransactions }}
          </a-tag>
        </template>
        <CreditLetterExtract
          :creditLetter="creditLetter"
          v-if="activeTab === 'extract'"
          @onCreateTransaction="onCreateTransaction"
        />
      </aTabPane>

      <aTabPane key="notes">
        <template #tab>
          Notas
          <a-tag v-if="totalNotes != 0" class="ml-5" color="blue">
            {{ totalNotes }}
          </a-tag>
        </template>
        <Notes
          :moduleId="creditLetter.details.id"
          v-if="activeTab === 'notes'"
          @onLoadNotes="onLoadNotes"
          module="credit-letter"
        />
      </aTabPane>

      <aTabPane key="logs">
        <template #tab>
          Logs
          <a-tag v-if="totalLogs != 0" class="ml-5" color="blue">
            {{ totalLogs }}
          </a-tag>
        </template>
        <LogsTimelineV2
          v-if="activeTab === 'logs'"
          class="mt-20"
          module="credit-letter"
          @onLoadLogs="onLoadLogs"
          :id="creditLetter.details.id"
        />
      </aTabPane>
    </aTabs>
  </div>
</template>

<script>
// reuse
import creditLetterReusables from "../mixins/creditLetterReusables";
import reusableThings from "@/mixins/general/reusableThings";

// comp
import CreditLetterModalHeader from "../sections/CreditLetterModalHeader.vue";
import CreditLetterExtract from "../tabs/CreditLetterExtract.vue";
import Notes from "@/components/general/Notes.vue";
import LogsTimelineV2 from "@/components/logs/LogsTimelineV2.vue";
import CreditLetterForm from "../forms/CreditLetterForm.vue";

export default {
  name: "CreditLettersModal",
  props: {
    creditLetterId: String,
    creditCardStatusList: Array,
  },
  components: {
    CreditLetterModalHeader,
    CreditLetterExtract,
    CreditLetterForm,
    LogsTimelineV2,
    Notes,
  },
  mixins: [creditLetterReusables, reusableThings],
  data() {
    return {
      activeTab: "data",
      totalTransactions: 0,
      totalNotes: 0,
      totalLogs: 0,
    };
  },
  mounted() {
    this.getCreditLetter();
  },
  methods: {
    getCreditLetter() {
      this.getCreditLetterById(this.creditLetterId).then(({ data }) => {
        this.creditLetter.details = data;

        this.creditLetter.details.raw.issuer_id =
          data.raw.issuer_id != 0 ? data.raw.issuer_id : undefined;
      });
    },
    onCreateTransaction(total) {
      this.getCreditLetter();
      this.totalTransactions = total;
    },
    onLoadNotes(meta) {
      this.totalNotes = meta.total;
    },
    onLoadLogs(meta) {
      console.log(meta);
      this.totalLogs = meta.total;
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
